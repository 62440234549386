/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar Header component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React from "react";
import { useNavigate } from "react-router";
import { Grid, Typography } from "@mui/material";
import { HomeRoute } from "../PageRouting";

/* #endregion imports */

const AANavHeader = () => {

  const navigate = useNavigate();

  const handleClickEvent = () => {
    navigate(HomeRoute);
  };

  return (
    <Grid container spacing={0} alignItems={"center"} alignContent={"left"} sx={{border:0, width:"100%"}}>
    <Grid item sx={{ marginLeft: 0, marginTop:1 }}>
      <img
        src="/images/idox.png"
        alt="IDOX Group"
        width="31"
        height="27"
        onClick={handleClickEvent}
      />
    </Grid>
    <Grid item sx={{ marginLeft: 1}}>
      <Typography variant="h5">
        Symphony Security
      </Typography> 
  </Grid>
  </Grid>

  );
};

export default AANavHeader;
