import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { env } from './../env'

import LoginDialogV1 from "./LoginDialogV1";
import LoginDialogV2 from "./LoginDialogV2";
import AuthenticateDialog from "./AuthenticateDialog";


LoginDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

function LoginDialog({ title, message }) {

  const [version, setVersion] = useState(null);

  useEffect(() => {
      if(!version) 
          setVersion(env.REACT_APP_SECURITY_VERSION);
  },[version])

  const bIsV1Security = version === "1"; 

  return bIsV1Security ?
    (
        <LoginDialogV1
            title={title}
            message={message}
        />

     ) :
    (   
      <>
      <AuthenticateDialog
        title="Symphony Security Authenticate"
        message="Authenticate with the code emailed to you."
      />

      <LoginDialogV2
        title={title}
        message={message}
      />
      </>
    )
}

export default LoginDialog;
