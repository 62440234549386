import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import AADataTable from "./AADataTable"


const UserListGrid = ({columns, rows, height, onDoubleClick}) => 
{
    const [errorText, setErrorText] = useState(null);

return !rows ?
    (
        <h1>Loading</h1>
    )
    :    
    (
        <Grid container justifyContent="flex-start" sx={{height:{height}}}>
            <Grid item xs={12} sx={{ m: 2, height: {height} }}>
                <AADataTable columnData={columns} rowData={rows} height={height} handleDoubleClick={onDoubleClick} />
            </Grid>
        </Grid>
    );
}

export default UserListGrid;