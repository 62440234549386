import React from "react";
import { FormatDateTime } from "../../utils/HelperUtils";

export const UserTableColumns = [
    {
        headerName: "Id",
        field: "id",
        sortable: false,
        hide: true,
        filterable:false

    },
    {
        headerName: "FirstName",
        field: "firstName",
        sortable: true,
        filterable:false,
        flex: 0.25,
        minWidth: 120,
        width:160,
    },
    {
        headerName: "LastName",
        field: "lastName",
        sortable: false,
        filterable:false,
        flex: 0.25,
        minWidth: 120,
        width:160,
    },
    {
        headerName: "Email",
        field: "email",
        sortable: false,
        filterable:false,
        flex: 0.25,
        minWidth: 120,
        width:160,
    },
    {
        headerName: "Organisation",
        field: "organisation",
        sortable: false,
        filterable:false,
        flex: 0.25,
        minWidth: 120,
        width:160,
    },
    {
        headerName: "Created",
        field: "created",
        sortable: false,
        filterable:false,
        flex: 0.25,
        valueFormatter: (param) => { return FormatDateTime(param.value); },
        minWidth: 120,
        width:160,
    },
    {
        headerName: "Updated",
        field: "lastUpdated",
        sortable: false,
        filterable:false,
        flex: 0.25,
        valueFormatter: (param) => { return FormatDateTime(param.value); },
        minWidth: 120,
        width:160,
    }
];

export const UserInfoColumns = [
    {
        headerName: "Id",
        field: "id",
        sortable: false,
        hide: true,
        filterable:false
    },
    {
        headerName: "Key",
        field: "key",
        sortable: false,
        hide: false,
        filterable:false,
        flex: 0.5
    },
    {
        headerName: "Value",
        field: "value",
        sortable: false,
        hide: false,
        filterable:false,
        flex: 0.5
    }
];

