import React, { useEffect } from "react";
import { EditUserWizardContextProvider } from "../context/editUserWizardContext ";
import EditUserWizardDialog from "./user/EditUserWizardDialog"

const EditUserWizard = ({closeDialogHandler, updateUserHandler, userDetails, continueError}) =>  {
 
  const updateTheUserHandler = (response, details) => {
    console.log("updatetheUserHandler",response, details)
    updateUserHandler(response,details);
  }

  return (
    <EditUserWizardContextProvider>
       <EditUserWizardDialog closeDialogHandler={closeDialogHandler} updateUserHandler={(response,details) => updateTheUserHandler(response,details)} userDetails={userDetails} />
    </EditUserWizardContextProvider>
  );
}

export default EditUserWizard;