/* #region header */
/**************************************************************************************************
//
//  Description: Aligned Assets Navigation Bar component
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   02.07.21 Sean Flook         WI39??? Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */

import React, { useState, useEffect } from "react";
import { useNavigate , useLocation } from "react-router";
import { Drawer, Grid, Box, Typography, Chip } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';

import AAUserAvatar from "./AAUserAvatar";
import Toolbar from "@mui/material/Toolbar";

import { HomeRoute } from "../PageRouting";

import { useTheme } from '@mui/material/styles';


/* #endregion imports */

const AANavContent = (props) => {
  const theme = useTheme();

  const navBarWidth = "272px";

  const navigate = useNavigate();
  const location = useLocation();

  const homeText = "Home";

  const iconSize = "medium";

  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const handleItemClick = (routeName) => {
    navigate(routeName);
    setActiveRoute(routeName);
  };


  function navigationStyle(pageRoute) {
    return (pageRoute === activeRoute) ? { color: "#2A6EBB" } : { color: "#7f7f7f"};
  }

  function SelectedStyle(pageRoute) {
    return (pageRoute === activeRoute) ? { backgroundColor:"#dff1f9",marginRight:2, } : { backgroundColor: theme.backgroundColor,marginRight:2 };
  }


  const GridChip = (count, route) => {
    return (
      <Grid item xs={4}>
      <Grid container justifyContent={"flex-end"}>
        <Grid item>
            <Chip label={count} sx={navigationStyle(route)}  size="small" />
        </Grid>
      </Grid>              
    </Grid>
  )};

  
  const GetRouteIcon = (route) =>
  {
    switch(route)
    {
      case HomeRoute : return <HomeIcon fontSize={iconSize} sx={navigationStyle(route)}/>;

      default: return null;
    }
  }

  const GetRouteChip = (route) =>
  {
    switch(route)
    {
      default: return null;
    }
  }


  const NavIconWithText = (route, text, count) => {
    const icon = GetRouteIcon(route);
    const chip = GetRouteChip(route);
    return (
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" onClick={() => handleItemClick(route)}>
        <Grid item sx={{ marginLeft:1, marginRight: 1}} xs={1}>
            { icon }   
        </Grid>
        <Grid item sx={{ marginLeft: 0, marginTop:0 }} xs={4}>
          <Typography variant="body1" aria-label={text} sx={navigationStyle(route)}>
            {text}             
          </Typography>
        </Grid>
        { chip }
      </Grid>
  )}

  useEffect(() => {
    setActiveRoute(location.pathname);
  },[location]);

  return (
    <Drawer variant="permanent" sx={{ width: navBarWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: navBarWidth, boxSizing: 'border-box' },
                }} >
      <Toolbar />
       <Box sx={{ overflow: "clip" }}>
      <Grid
        sx={{
          paddingTop: theme.spacing(2),
          height: "91vh",
        }}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid item xs>
          <Grid container direction="column" alignItems="left" justifyContent="flex-start"  sx={{ marginLeft:1}} >
            
            <Grid item xs sx={SelectedStyle(HomeRoute)}>
                {NavIconWithText(HomeRoute, homeText)}
            </Grid>

          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="left" justifyContent="flex-end" >
            <AAUserAvatar handleClick={() => {window.location.reload();}} />
          </Grid>
        </Grid>
      </Grid>
      </Box>
    </Drawer>
  );
};

AANavContent.propTypes = {};
AANavContent.defaultProps = {};

export default AANavContent;
