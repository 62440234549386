import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserContext } from "./../context/userContext";
import { LoginUser } from "./../configuration/AAConfig_Security";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";
import CloseIcon from "@mui/icons-material/Close";
import { FormRowStyle, FormInputStyle } from "../utils/AAStyles";
import { useTheme } from '@mui/material/styles';

LoginDialogV2.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

function LoginDialogV2({ title, message }) {
  const theme = useTheme();

  const { showLogin, updateShowLogin, updateLoginDetails, updateAuthenticateId, updateUserError, userError, updateUseAuthenticate } = useContext(UserContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(userError);

  const handleUsernameChangeEvent = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChangeEvent = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
    };

    const IsLoginValid = () => {
        const bUserNameEmpty = !username || username.length === 0;
        const bPasswordEmpty = !password || password.length === 0;

        if (bPasswordEmpty || bUserNameEmpty) return false;

        return true;
    }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

    const handleLoginClick = async () => {

      console.log("login dialog",username, password);

        if (!IsLoginValid()) {
            updateUserError("One or more credentials is empty.");
            return;
        }
        
        const loginRes = await LoginUser(username,password,setLoginError)
        //updateLoginDetails(loginRes);
        updateAuthenticateId(loginRes.authorizeId);
        
        //console.log("login result",loginRes);
      if (loginRes) {
/*        
        const userInfo = await WhoAmI(loginRes.token);

         if (userInfo) 
         {
            //console.log("UserInfo",userInfo);
            if(userInfo.rights.findIndex((x) => (x === "SuperAdministrator" || x === "OrganisationAdministrator")) < 0)
            {
                //console.log("Insufficient Rights");
                setLoginError( "You dont have sufficient rights to edit other users");
            }    
            else
            {  
              if (userInfo.active && !userInfo.isDeleted) {
                const loggedInUser = {
                  token: loginRes.token,
                  ...userInfo,
                };
                // console.log("[SF] loggedInUser", loggedInUser);
                  //userContext.onUserChange(loggedInUser);
                  //updateLoginDetails(loginRes);
                  
                  //updateCurrentUser(userInfo);
                  updateShowLogin(false);
              } 
              else 
              {
                  updateCurrentUser(null);
                //userContext.onUserChange(null);
                setLoginError( !userInfo.active ?
                                "You are not an active user on this system." :
                                "This user has been deleted.");
              }
            }
          } 
          else 
          {
            setLoginError("Unable to get user information.");
          }
*/
      } 
      else 
      {
         updateUserError("Unable to login with the supplied credentials.");
      }
  };

    const handleCancelClick = () => {
        updateLoginDetails(null);
        updateShowLogin(false);
  };

  useEffect(() => {
    updateUseAuthenticate(true);
  },[])


  useEffect(() => {
    setLoginError(userError);
  },[userError])

  useEffect(() => 
  {
    if(loginError && loginError.length > 0 )
    {
        if(password || password.length > 0) setPassword("");
        //if(username || username.length > 0) setUsername("");
        updateShowLogin(true);
    } 
  }, [loginError]);  

  return (
    <Dialog
      open={showLogin}
      aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      maxWidth="xs"
      disableEnforceFocus 
    >
      <DialogTitle
        id="confirm-edit-loss-dialog"
        sx={{ color: "#FFFFFF", backgroundColor: "#2A6EBB" }}
      >
        {title ? title : "Login"} (v2)
      </DialogTitle>
      <DialogContent sx={{ marginTop: theme.spacing(1) }}>
        {loginError ? (
          <Typography variant="body1" color="error">
            {loginError}
          </Typography>
        ) : (
          <Typography variant="body1">{message}</Typography>
        )}
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={FormRowStyle()}
        >
          <Grid item xs={3}>
            <Typography
              id="aa-text-label-username"
              variant="body2"
              color="initial"
              align="left"
            >
              Username*
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="aa-text-textfield-username"
              sx={FormInputStyle()}
              fullWidth
              required
              variant="outlined"
              margin="dense"
              size="small"
              value={username}
              onChange={handleUsernameChangeEvent}
              aria-labelledby="aa-text-label-username"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography
              id="aa-text-label-password"
              variant="body2"
              color="initial"
              align="left"
            >
              Password*
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="aa-text-textfield-password"
              sx={FormInputStyle()}
              type={showPassword ? "text" : "password"}
              fullWidth
              required
              variant="outlined"
              margin="dense"
              size="small"
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordClick}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{
                        "&:hover": {
                          color: "#2A6EBB",
                        },
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handlePasswordChangeEvent}
              aria-labelledby="aa-text-label-password"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <Button
          onClick={handleLoginClick}
          autoFocus
          variant="contained"
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<LoginIcon />}
        >
          Login
        </Button>
        <Button
          onClick={handleCancelClick}
          sx={{
            color: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginDialogV2;
