/**************************************************************************************************
//
//  Description: wizardContext
//
//  Copyright:    © 2023 Idox Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   06.01.23 Peter Bryden
//#endregion Version 1.0.0.0 changes
//
//-------------------------------------------------------------------------------------------------*/

import React, { useEffect, useState } from "react";

const EditUserWizardContext = React.createContext();
EditUserWizardContext.displayName = "editUserContext";

function EditUserWizardContextProvider({props, children }) {

    /*
  "password": "dummyuser",
  "firstName": "dummy",
  "lastName": "user",
  "auditName": "dummyuser_pjb_only",
  "email": "dummyuser@example.com",
  "organisation": "IDOX",
  "rights": ["User"],
  "extraInformation": []
    */

    const [editUser, setEditUser] = useState(null);

    const [userPassword, setuserPassword] = useState(null);
    const [userFirstName, setUserFirstName] = useState(null);
    const [userLastName, setUserLastName] = useState(null);
    const [userAuditName, setUserAuditName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userOrganisation, setUserOrganisation] = useState(null);
    const [userRights, setUserRights] = useState(null);
    const [userExtraInformation, setUserExtraInformation] = useState(null);
  


    useEffect( () => {
        //console.log("EditUserWizardContext - editUser ",editUser)      
    }, [editUser]);


    function SetEditUser(userProperties)
    {
        setEditUser(userProperties);
    }    

    function GetEditUser()
    {
        return editUser;
    }    

    function ClearEditUser() {
        SetEditUser({
            id: "",
            password: "",
            firstName: "",
            lastName: "",
            auditName: "",
            email: "",
            organisation: "",
            rights: [],
            extraInformation: []
        })
    }



/*

    function GetFileId()
    {
        console.log(`NewUserWizardContext :: GetFileId called`, jobFileId);     
        return jobFileId;
    }    

    function SetFileId(file_id)
    {
        console.log(`jobwizardContext :: SetFileId called`);     
        setJobFileId(file_id);
    }    

    function GetFileDetails()
    {
        console.log(`jobwizardContext :: GetFileDetails called`);     
        return jobFileId;
    }    

    function SetFileId(file_id)
    {
        console.log(`jobwizardContext :: SetFileDetails called`);     
        setJobFileId(file_id);
    }    


    function SetJobDetails(job_details) {
        console.log(`jobwizardContext :: SetJobDetails called`);
        setJobFileDetails(job_details);
    }

    function GetJobDetails() {
        console.log(`jobwizardContext :: GetJobDetails called`);
        return jobFileDetails;
    }

    function SetJobFileDetails(file_details) {
        console.log(`jobwizardContext :: SetJobFileDetails called`);
    }

    function GetJobFileDetails() {
        console.log(`jobwizardContext :: GetJobFileDetails called`);
        return jobFileDetails;
    }

    function SetJobColumnMatches(column_matches) {
        console.log(`jobwizardContext :: SetJobColumnMatches called`);
        setJobColumnMatches(column_matches);
    }

    function GetJobCoumnMatches() {
        console.log(`jobwizardContext :: GetJobColumnMatches called`);
        return jobColumnMatches;
    }

    function ClearData() {
        setJobName(null);
        setJobDesc(null);
        setJobFileId(null);
        setJobFileDetails(null);
        setJobColumnMatches(null);
    }
    
    return (
        <JobWizardContext.Provider value={{ GetUserDetails, SetUserDetails, SetUserRoles, GetUserRolesId, SetExtraInformation, GetExtraInformation, SetUserPassword, GetUserPassword, ClearData }}>
            {children}
        </JobWizardContext.Provider>
    )
*/    
return (
    <EditUserWizardContext.Provider value={{ GetEditUser, SetEditUser, ClearEditUser  }}>
        {children}
    </EditUserWizardContext.Provider>
)

}

export { EditUserWizardContextProvider, EditUserWizardContext }
