import React, { useEffect, useState} from "react";
import { StyledWizardDialog, StyledWizardDialogTitle } from "./StyledWizardDialog";
import {
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Button
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { FormRowStyle, FormInputStyle } from "../../utils/AAStyles";
import { useTheme } from '@mui/material/styles';
import ContinueButton from "../../components/continueButton";
import BackButton from "../../components/backButton";
import {UserInfoColumns}  from "../../components/Data/DataTableColumns_User"

const UserInfoDialog = ({userDetails,closeDialogHandler,continueHandler, backHandler, changeInfoHandler}) =>  {
  const theme = useTheme();

  const [contineEnabled, setContinueEnabled] = useState(true);
  const infoColumns = UserInfoColumns;

  const onCancelHandler = (event,reason) => { 
    if(reason !== "backdropClick") 
      closeDialogHandler();
  };

  const extraInfoFromUserDetails = () =>
  {
    return (userDetails && userDetails.extraInformation) ? userDetails.extraInformation.map((x,idx) => { return {id:idx, ...x}}) : [];
  }

  const [updatedUserDetails, setUpdatedUserDetails] = useState(userDetails)
  const [extraInfo, setExtraInfo] = useState(extraInfoFromUserDetails());
  const [selectedRow, setSelectedRow] = useState();
  const [editedKey, setEditedKey] = useState("");
  const [editedValue, setEditedValue] = useState("");
  
  const [trimmedKey, setTrimmedKey] = useState("");
  const [trimmedValue, setTrimmedValue] = useState("");

  const [addEnabled, setAddEnabled] = useState(false);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const [removeEnabled, setRemoveEnabled] = useState(false);

  useEffect(() => {
    if(selectedRow)
    {
      setEditedKey(selectedRow.key);
      setEditedValue(selectedRow.value);
    }
  },[selectedRow])


  useEffect(() => {
    setTrimmedKey(editedKey.trim());
  },[editedKey])

  useEffect(() => {
    setTrimmedValue(editedValue.trim());
  },[editedValue])

  const setButtonState = () => {
    if((trimmedKey && trimmedKey.length > 0) && (trimmedValue && trimmedValue.length > 0))
    {
      const keyIdx = extraInfo.findIndex((x) => x.key === trimmedKey);

      setAddEnabled(keyIdx < 0)
      setUpdateEnabled( keyIdx >= 0 && (extraInfo[keyIdx].value != trimmedValue));  
      setRemoveEnabled(keyIdx >= 0)
    }
    else
    {
        disableButtons();
    }
  }

  useEffect(() => {
    setButtonState();
  },[trimmedKey])

  useEffect(() => {
    setButtonState();
  },[trimmedValue])

  const disableButtons = () => {
    setAddEnabled(false);
    setUpdateEnabled(false);
    setRemoveEnabled(false);
  }

  useEffect(() => {
    if(extraInfo != extraInfoFromUserDetails())
      setUpdatedUserDetails({...updatedUserDetails, "extraInformation" : extraInfo.map((x) =>  { return {key:x.key, value: x.value}})});

    setButtonState()  
  },[extraInfo])

  const updateRightHandler = () => {

    const keyIdx = extraInfo.findIndex((x) => x.key === trimmedKey);
    if(keyIdx >= 0)
          extraInfo[keyIdx].value = trimmedValue;

  }

  const addRightHandler = () => {
     const maxId = (extraInfo.length > 0) ? Math.max(...extraInfo.map(x => x.id))+1 : 0;
     setExtraInfo([...extraInfo,{id:maxId,key:trimmedKey,value:trimmedValue}])
  }

  const removeRightHandler = () => {
      setExtraInfo(extraInfo.filter(x => x.key !== trimmedKey))
  }
  
  return (
    <StyledWizardDialog
      onClose={onCancelHandler}
      open
      //aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      disableEnforceFocus
      disableEscapeKeyDown
      maxWidth="md"
    >
      <StyledWizardDialogTitle id="customized-dialog-title" onClose={onCancelHandler} title="User Extra Information" />
      <DialogContent sx={{ marginTop: theme.spacing(0) }}>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              sx={FormRowStyle()}
            >

                  <Grid item xs={12} sx={{ m: 1, height: "20vh", flexGrow: 1 }}>
                    <DataGrid
                      disableColumnMenu={true}
                      disableColumnSelector={true}
                      disableColumnFilter={true}
                      disableDensitySelector={true}
                      disableSelectionOnClick={true}
                      hideFooter={true}
                      columns={[...infoColumns]}
                      rows={extraInfo}
                      onRowClick={(params) => setSelectedRow(params.row)}
                    />
                 </Grid>

                  <Grid item xs={6} >
                        <TextField
                              id="change-extra-info-key-text"
                              label="Key"
                              sx={FormInputStyle() && {minWidth:"95%"}}
                              type="text"
                              variant="outlined"
                              margin="dense"
                              size="small"
                              value={editedKey}
                              onChange={(e) => setEditedKey(e.target.value)}
                              xs={12}
                            />
                    </Grid>

                    <Grid item xs={6} >
                        <TextField
                              id="change-extra-info-key-text"
                              label="Value"
                              sx={FormInputStyle() && {minWidth:"95%"}}
                              type="text"
                              variant="outlined"
                              margin="dense"
                              size="small"
                              value={editedValue}
                              onChange={(e) => setEditedValue(e.target.value)}
                              xs={12}
                            />
                    </Grid>

                  <Grid item xs={2}>
                       <Button disabled={!updateEnabled} onClick={(e) => updateRightHandler()} variant="outlined" sx={FormInputStyle()}>Update</Button>
                  </Grid>
                  <Grid item xs={2}>
                      <Button disabled={!addEnabled} onClick={(e) => addRightHandler()} variant="outlined" sx={FormInputStyle()}>Add</Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button disabled={!removeEnabled} onClick={(e) => removeRightHandler()} variant="outlined" sx={FormInputStyle()}>Remove</Button>
                  </Grid>


            </Grid>
          
      </DialogContent>

      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <BackButton backHandler={() =>backHandler(updatedUserDetails)} />
        <ContinueButton continueHandler={() => continueHandler(updatedUserDetails)} enabled={contineEnabled} />
      </DialogActions>
    </StyledWizardDialog>
  );
}

export default UserInfoDialog;