import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTheme } from '@mui/material/styles';
import { Snackbar, Alert, Grid,  Button, Card, Typography, CardContent } from "@mui/material";
import { UserContext } from "../context/userContext";
import {UserTableColumns} from "../components/Data/DataTableColumns_User"

import UserListGrid from "../components/userListGrid";
import ResetPasswordDialog from "../dialogs/user/ResetPasswordDialog";
import EditUserWizard from "../dialogs/EditUserWizard"
import {GetUsers, UpdateUserPassword} from "../configuration/AAConfig_Security"


const HomePage = () => {
    const {currentToken, logoff } = useContext(UserContext);
    const theme = useTheme();

    const [errorText, setErrorText] = useState(null);
    const [userData,setUserData] = useState(null);
    const [editedUserId, setEditedUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showResetPassword, setShowResetPassword] = useState();
    const [showEditUser, setShowEditUser] = useState();
    const [editedUser, setEditedUser] = useState();
    const [errorAlert, setErrorAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
    const [successMsg, setSuccessMsg] = useState();
    
    useEffect(() => {

        //console.log("isloading useeffect::",isLoading, userData, errorText);

        async function GetUserData()
        {
          
          if(isLoading && !userData)
          {
            //console.log("isloading useeffect::loaddata",currentToken, currentUser);    

            const usersInfo = await GetUsers(currentToken, (err) =>setErrorText(err));
            setUserData(usersInfo);
          }   
        };

        if(isLoading && !errorText)
        {
            GetUserData();
        }
        else
        {
            if(errorText)
            {
               console.log("Logging user off because error::",errorText);
               logoff();
            }        
        }

      },[isLoading]);

      useEffect(() => {
        //console.log("loading",isLoading, !userData, errorText);
        if(!errorText)
            setIsLoading(!userData);
      },[userData]);

    useEffect(() => {
        setShowEditUser(editedUser ? true : false);
    },[editedUser]);
   
    const onClickResetPassword = (e, id) => {
        e.stopPropagation();        
        //const callResponse = UpdateUserPassword(currentToken, id, password, setErrorText)
        //console.log("resetpassword",  editedUserId, password);
        
        setEditedUserId(id);
        setShowResetPassword(true);
    };
    
    const ResetPasswordButton = (params)  =>
    {
          return <Button onClick={(e) => onClickResetPassword(e,params.id)}>Reset</Button>;
    }

    const userGridColumns = 
       [ ...UserTableColumns,
        {
            field: "",
            headerName: "Password",
            sortable: false,
            renderCell: ResetPasswordButton
        }];


    const updatePassword = async(password) => { 
        
        setErrorText();
        const callResponse = await UpdateUserPassword(currentToken, editedUserId, password, setErrorText)
        //console.log("resetpassword", callResponse, editedUserId, password);
        
        if(callResponse.status === 200)
        {
            setSuccessMsg("Password Reset")
            setSuccessAlert(true);
            setShowResetPassword(false);
        }
        else
        {
            setErrorMsg("An error has occurred while trying to reset the password.")
            setErrorAlert(true);
        }    
    }


    const updateUser = (response, details) => {
        
        //console.log("updateuser ",response,details);

        if(response && response.status === 200)
        {
            setShowEditUser(false)
            setUserData(null);
            setSuccessMsg(`User ${details.email} has been updated`)    
            setSuccessAlert(true);
        }    
        else if(response && response.status === 201)
        {
            setShowEditUser(false)
            setUserData(null);
            setSuccessMsg(`User ${details.email} has been created`)    
            setSuccessAlert(true);
        }
        else
        {
            setErrorMsg(`ERROR :: ${details.email} has NOT been ${(details && details.id.length > 0) ? "created" : "updated"}`)                    
            setErrorAlert(true);
        }        
    }

    const handleEditUser = (params) => {
        console.log("handledoubleclick",params)
        if(params && params.row)
            setEditedUser({...params.row});
    }

    const handleNewUserClick = () => {
       console.log("handleNewUserClick");
       setEditedUser({
        "id": "",
        "firstName": "",
        "lastName": "",
        "auditName": "",
        "email": "",
        "organisation": "",
        "active": true,
        "rights": [],
        "extraInformation": []
        });
    }


    const renderForm = () => {
            return (
             <>
            <Snackbar open={errorAlert} autoHideDuration={4000} onClose={() => setErrorAlert(false)}>
                <Alert onClose={()=>setErrorAlert(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>

            <Snackbar open={successAlert} autoHideDuration={4000} onClose={() => setSuccessAlert(false)}>
                <Alert onClose={()=>setSuccessAlert(false)} severity="success" sx={{ width: '100%' }}>
                    {successMsg}
                </Alert>
            </Snackbar>

            <Grid container spacing={1} sx={{ paddingTop: theme.spacing(10) }} direction="column" justifyContent="space-between" alignItems="left">
            <Grid item xs><Button onClick={handleNewUserClick}>Add User</Button></Grid>

                <Grid item xs>
                {
                    showResetPassword ? <ResetPasswordDialog closeDialogHandler={() => setShowResetPassword(false)} continueHandler={async(pwd)=> await updatePassword(pwd)} continueError={errorText} /> : <Fragment /> 
                }
                </Grid>
                <Grid item xs>
                {
                    showEditUser ? <EditUserWizard userDetails={editedUser} closeDialogHandler={() => setEditedUser()} updateUserHandler={(response,details)=> updateUser(response,details)} continueError={errorText} /> : <Fragment /> 
                }
                </Grid>   
                <Grid item xs>
                    <Card variant="outlined" sx={{width:"95%", display:"flex" }}>
                        <CardContent sx={{width:"95%", border:0 }}>
                            {(errorText) ? (<Alert severity="error">{errorText}</Alert>) : (<Fragment />)}
                            <Typography variant="h5" component="div" sx={{ paddingTop: theme.spacing(2) }} >
                                User List
                            </Typography>
                            <UserListGrid columns={userGridColumns} rows={userData} height={"80vh"} onDoubleClick={(params)=>handleEditUser(params)} />                        
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>   
         );
    };

    const renderBasic = () => {
        return (
            <Grid container spacing={1} sx={{ paddingTop: theme.spacing(50) }} direction="column" justifyContent="space-between" alignItems="left">
            <Grid item>
                <h1>Loading data, please wait...</h1>
            </Grid>
            </Grid>
        );
    };


    const renderPage = () => {
        return (!isLoading && currentToken) ? renderForm() :  renderBasic();
    }

    return renderPage();
};

export default HomePage;
